<template>
  <div class="content-layout-fixer">
    <div class="content-wrapper">
      <div class="top">
        <div class="chef"></div>
        <div class="title">
          Merhaba, Ben Şef! <br />
          Bugün sana nasıl yardımcı olabilirim?
        </div>
      </div>
      <iframe
        :src="`${setIframeSrc()}`"
        height="512"
        width="650"
        scrolling="no"
        style="border: 0;"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Chatbot',
  methods: {
    setIframeSrc() {
      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
      const isDevOrQaEnvironment = apiBaseUrl.includes('qa') || apiBaseUrl.includes('dev');

      const environmentPrefix = isDevOrQaEnvironment
        ? apiBaseUrl.includes('qa')
          ? 'qa'
          : 'dev'
        : 'pmaktif';

      const iframeSrc = isDevOrQaEnvironment
        ? `https://${environmentPrefix}.pmaktiftr-${environmentPrefix}.aws.pmicloud.biz/chatbot.html`
        : `https://${environmentPrefix}.com/chatbot.html`;

      console.log({ iframeSrc });

      return iframeSrc;
    },
  },
  mounted() {
    document.documentElement.style.setProperty('--mb-mwc-font-stack', 'Roboto, sans-serif');
  },
};
</script>

<style lang="scss" scoped>
.content-layout-fixer {
  height: calc(100vh - 100px);
}
.content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
iframe {
  width: 500px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  flex: 1;
}
.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  .chef {
    height: 80px;
    width: 80px;
    background-image: url('~@/assets/images/kulaklik-chatbot-buyuk.png');
    background-position: top center;
    background-size: 100%;
    border-radius: 50%;
  }
  .title {
    font-size: 16px;
    letter-spacing: 1px;
    margin-top: 10px;
    text-align: center;
  }
}
</style>
